export const english = {
    main: {
        hello: 'Welcome!!!',
        logout: 'Sign out'
    },
    error: {
        title: 'Error: ',
        mainPage: 'Main page',
        pageNotFound: 'Page is not found',
        accessDenied: 'Access denied'
    },
    routes: {
        menu: 'Menu',
        profile:'Profiles',
        partners: 'Privilege club',
        favorites: 'Favorites',
        administration: 'Administration',
        users: 'Users',
        emailVerified: 'Email confirmed',
        categories: 'Categories of partners',
        partner: 'Partner'
    },
    email: {
        emailConfirmed: 'Your email has been confirmed',
        goToCabinet: 'Go to the cabinet',
        follow: 'Follow'
    },
    coupon: {
        category: {
            title : 'Categories of partners',
            table:{
                title : 'Categories',
                name: 'Title',
                add: 'Add',
                edit: 'Edit'
            },
            card: {
                title: 'Category',
                name: 'Title',
                cancel: 'Cancel',
                save: 'Save'
            },
            shortlist: {
                all: 'All'
            }
        },
        partners: {
            title: 'Partners',
            link: 'Link',
            linkName: 'Text of link',
            promoCode: 'PromoCode',
            certificate: 'Certificate',
            getDiscount: 'Get',
            list: {
                create: {
                    add: 'Add',
                    cancel: 'Cancel',
                    addPartner: 'Add partner'
                },
            },
            card: {
                name: 'Name',
                textLogo: 'Title instead of logo',
                price: 'Price',
                offer: 'Text in button',
                active: 'Active',
                category: 'Category',
                underLogoCard: 'Subtitle',
                textUnderButton: 'Text under button',
                description: 'Description',
                conditions: 'Conditions',
                howToGet: 'How to get',
                phone: 'Phone',
                site: 'Site',
                promocodeDescription: 'Promocode description',
                downloadCertificate: 'Download certificate',
                cancel: 'Cancel',
                save: 'Save'
            }
        }
    },
    users: {
        list: {
            title: 'Users',
            table: {
                name: 'FIO',
                lastName:'Surname ',
                firstName:'First name',
                phoneNumber: 'Phone number',
                email: 'Email',
                role: 'Role',
                action: 'Action',
                viewUser: 'Edit',
                blockUser: 'Block',
                unblockUser: 'Unblock',
                notAllowedBlockYourself: 'You can\'t block yourself',
                notAllowedUnBlockYourself: 'You can\'t unblock yourself'
            },
            filter: {
                search: 'Search...',
                isEnabled: 'Active',
                find: 'Find',
                clear: 'Clear'
            }
        },
        card: {
            title: 'Personal information',
            lastName: 'Last name',
            firstName: 'First name',
            birthDate:'Date of birth',
            sex: {
                man: 'Man',
                woman: 'Woman'
            },
            email: 'Email',
            emailVerify: 'Verify',
            phoneNumber: 'Phone number',
            role: 'Role',
            createDate: 'Registration date',
            save: 'Save',
            forSendingIntrestingNews: 'The most interesting offers will be sent there',
            uniqueIdentifier: 'Unique identifier',
            uniqueIdentifierNotChange: 'Unique identifier, you can\'t change this',
            selfRoleChangingError: 'You cannot change the role to yourself',
            allowChangeEmailOnceIn3Minutes: 'Changing email is allowed only once every 3 minutes.',
            emailVerifyMessageSent: 'Email confirmation email sent'
        }
    },
    forms: {
        validations: {
            required: "Value is required",
            minLength: "This field should be at least {min} characters long.",
            maxLength: "The maximum length allowed is {max}.",
            numeric: "Value must be numeric",
            email: "Invalid format",
            invalidFormat: "Invalid format",
            dateGreaterThanNow: "Date cannot be greater than today's date",
            birthDateAlreadyChanged: "The date of birth has already changed. Please contact your administrator to change again",
            invalidForms: 'Invalid form',
            leaveQuestion: 'Do you really want to leave? you have unsaved changes!'
        },
        shared: {
            phrases: {
                clipboard: 'Successfully buffered',
                personalPolicy: 'Personal data policy',
                cookiePolicy: 'Cookie policy',
                personalData: 'Consent to the processing of personal data',
                feedback: 'Found a mistake? Write to ',
                copy: 'Copy',
                dataSaved: 'Data saved successfully'
            },
            back: 'Back',
            dropdown: {
                select: 'Select'
            },
            yesNo: {
                yes: 'Yes',
                no: 'No'
            },
            date: {
                placeholder: 'DD.MM.YYYY'
            },
            text: {
                show: "Show",
                hide: "Hide"
            },
            email: {
                isVerified: "Mail address confirmed",
                isNotVerified: "Mail address not confirmed",
                emailVerifyMessageSent: "We have sent an e-mail to the indicated mail, please confirm receipt",
                verifyMessageResend: "Resend"
            },
            grid: {
                loading: "Loading...",
                load: "Load",
                outOf: "out of",
                noDataTitle: 'Nothing was found',
                noDataText: 'Simplify your request and try again'
            },
            notification:{
                success: 'Operation succeeded',
                error: 'Error',
                warning: 'Attention',
                information: 'Additional information'
            },
            fileInput: {
                download: 'Download file'
            }
        }
    }
}