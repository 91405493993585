export const russian =  {
    main: {
        hello: 'Добро пожаловать!!!',
        logout: 'Выход'
    },
    error: {
        title: 'Ошибка: ',
        mainPage: 'На главную',
        pageNotFound: 'Страница не найдена',
        accessDenied: 'Доступ запрещен'
    },
    routes: {
        menu: 'Меню',
        main:'Главная страница',
        profile:'Профиль',
        partners: 'Клуб привилегий',
        favorites: 'Избранное',
        administration: 'Администрирование',
        users: 'Пользователи',
        emailVerified: 'Email подтвержден',
        categories: 'Категории партнеров',
        partner: 'Партнер'
    },
    email: {
        emailConfirmed: 'Ваш email подтвержден',
        goToCabinet: 'Перейти в кабинет',
        follow: 'Перейти'
    },
    coupon: {
        category: {
            title : 'Категории партнеров',
            list:{
                title : 'Список категорий',
                name: 'Название',
                add: 'Добавить',
            },
            card: {
                title: 'Категория',
                name: 'Название',
                cancel: 'Отменить',
                save: 'Сохранить'
            },
            shortlist: {
                all: 'Все'
            }
        },
        partners: {
            title: 'Партнеры',
            link: 'Ссылка',
            linkName: 'Текст ссылки',
            promoCode: 'Промокод',
            certificate: 'Сертификат',
            getDiscount: 'Получить',
            list: {
                create: {
                    add: 'Добавить',
                    cancel: 'Отменить',
                    addPartner: 'Добавление партнера'
                },
            },
            card: {
                name: 'Название',
                textLogo: 'Заголовок вместо логотипа',
                price: 'Стоимость',
                offer: 'Текст в кнопке',
                active: 'Активен',
                category: 'Категория',
                underLogoCard: 'Подзаголовок',
                textUnderButton: 'Текст под кнопкой',
                description: 'Описание',
                conditions: 'Описание условий',
                howToGet: 'Как получить',
                phone: 'Телефон',
                site: 'Сайт',
                promocodeDescription: 'Описание промокода',
                downloadCertificate: 'Скачать сертификат',
                cancel: 'Отменить',
                save: 'Сохранить'
            }
        }
    },
    users: {
        list: {
            title: 'Список пользователей',
            table: {
                name: 'ФИО',
                lastName:'Фамилия',
                firstName:'Имя',
                phoneNumber: 'Номер телефона',
                email: 'Email',
                role: 'Роль',
                action: 'Действие',
                viewUser: 'Редактировать',
                blockUser: 'Заблокировать',
                unblockUser: 'Разблокировать',
                notAllowedBlockYourself: 'Нельзя заблокировать себя',
                notAllowedUnBlockYourself: 'Нельзя разблокировать себя'
            },
            filter: {
                search: 'Поиск...',
                isEnabled: 'Активен',
                find: 'Найти',
                clear: 'Очистить'
            }
        },
        card: {
            title: 'Личная информация',
            lastName: 'Фамилия',
            firstName: 'Имя',
            birthDate:'Дата рождения',
            sex: {
                man: 'Мужчина',
                woman: 'Женщина'
            },
            email: 'Email',
            emailVerify: 'Подтвердить',
            phoneNumber: 'Телефон',
            role: 'Роль',
            createDate: 'Дата регистрации',
            changeRole: 'Изменение роли',
            block: 'Заблокировать',
            unblock: 'Разблокировать',
            save: 'Сохранить',
            forSendingIntrestingNews: 'Самые интересные предложения будем слать туда',
            uniqueIdentifier: "Уникальный идентификатор",
            uniqueIdentifierNotChange: "Уникальный идентификатор, нельзя изменить",
            selfRoleChangingError: "Нельзя менять роль самому себе.",
            allowChangeEmailOnceIn3Minutes: 'Изменять email разрешено только раз в 3 минуты.',
            emailVerifyMessageSent: 'Письмо для подтверждения Email отправлено'
        }
    },
    forms: {
        validations: {
            required: "Поле должно быть заполнено.",
            minLength: "В поле должно быть не менее {min} символов(-а).",
            maxLength: "В поле должно быть не более {max} символов(-а).",
            numeric: "В поле должны быть только цифры.",
            email: "Неверный формат",
            invalidFormat: "Неверный формат",
            dateGreaterThanNow: "Дата не может быть больше текущей",
            birthDateAlreadyChanged: "Дата рождения уже менялась. Для повторного изменения обратитесь к администратору",
            invalidForms: 'На форме присутствуют ошибки'
        },
        shared: {
            phrases: {
                clipboard: 'Успешно сохранено в буфере',
                personalPolicy: 'Политика персональных данных',
                cookiePolicy: 'Политика обработки Cookie-файлов',
                personalData: 'Согласие на обработку персональных данных',
                feedback: 'Нашли ошибку? Напишите нам на',
                copy: 'Копировать',
                dataSaved: 'Данные успешно сохранены',
                leaveQuestion: 'Вы действительно хотите покинуть страницу? У Вас есть несохраненные изменения!'
            },
            back: 'Назад',
            dropdown: {
                select: 'Выбрать'
            },
            yesNo: {
                yes: 'Да',
                no: 'Нет'
            },
            date: {
                placeholder: "ДД.ММ.ГГГГ"
            },
            text: {
                show: "Показать",
                hide: "Скрыть"
            },
            email: {
                isVerified: "Адрес почты подтвержден",
                isNotVerified: "Адрес почты не подтвержден",
                emailVerifyMessageSent: "Мы отправили на указанную почту письмо, пожалуйста, подтвердите получение",
                verifyMessageResend: "Отправить еще раз"
            },
            grid: {
                loading: "Загрузка...",
                load: "Загрузить еще",
                outOf: "из",
                noDataTitle: 'Ничего не нашлось',
                noDataText: 'Упростите запрос и попробуйте снова'
            },
            notification:{
                success: 'Операция успешно выполнена',
                error: 'Ошибка',
                warning: 'Внимание',
                information: 'Доп. информация'
            },
            fileInput: {
                download: 'Скачать файл'
            }
        }
    }
}